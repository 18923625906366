import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';
import WigLoader from '@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader';

import { useFiltersContext } from '../context/FiltersContext';

import fetchJsonp from 'fetch-jsonp';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '../components/Analytics';
import { useTracking } from 'react-tracking';

export default function HomepagePage(props) {
  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  const {
    pageContext: {
      integratorData,
      contentMetaData,
      contentDataData,
      navigationData,
      navResultData,
      entireLocationsList,
      customFieldsJSON,
      config,
    },
  } = props;

  const siteConfig = config;

  const apiKey = siteConfig.apiKey || props.smartfeedID;
  const gdprKey = siteConfig.gdprKey || props.apiKey || props.smartfeedID;
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);
  const [vacancyLoading, setVacancyLoading] = useState(true);

  // const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  // const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
  //   'ap-signals-session-id'
  // );

  // const { trackEvent } = useTracking(
  //   { page: 'Homepage', location: props.location, apiKey },
  //   {
  //     dispatch: (data) =>
  //       trackAnalyticsEvent(
  //         data,
  //         cookies['ap-signals-user-id'],
  //         sessionIDValue,
  //         siteConfig
  //       ),
  //   }
  // );

  // useEffect(() => {
  //   trackAnalyticsPageView(
  //     props.location,
  //     cookies['ap-signals-user-id'],
  //     sessionIDValue,
  //     siteConfig
  //   );
  // }, [cookies, props.location, sessionIDValue, siteConfig]);

  useEffect(() => {
    if (apiKey) {
      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            'GetCompanyDetails' +
            '?id=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function(response) {
            return response.json();
          })
          .then(function(jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                '/CareerPage/' +
                'GetApplicantGetwayURL' +
                '?companyId' +
                '=' +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function(response) {
                return response.json();
              })
              .then(function(appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function(ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, 'error');
              });
          })
          .catch(function(ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [apiKey, gdprKey, siteConfig.sfAPIEndpoint]);

  const companyName =
    siteConfig?.companyName || props?.smartfeedCompanyData?.CompanyName || '';

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: 'postingtype',
        value,
      },
    };
    onSelectionChange(temp);
  };

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={apiKey}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      // trackEvent={trackEvent}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
      contentDataData={contentDataData}
      integratorData={integratorData}
      contentMetaData={contentMetaData}
    >
      {!vacanciesRouteLoading &&
      (siteConfig.siteConfig || smartfeedCompanyData) &&
      apiKey ? (
        <>
          <div className="homepage-background">
            <div className="homepage-banner">
              <div className="container">
                <div className="bannerWrapper">
                  <h1 className="h1-homepage">
                    {contentDataData[0].children[0].value}
                  </h1>
                  <h3>{contentDataData[0].children[1].value}</h3>
                  <a
                    href={contentDataData[0].children[2].value}
                    className="btn vf-banner-search-btn"
                  >
                    {contentDataData[0].children[2].text}
                  </a>
                </div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#ffffff"
                fillOpacity="1"
                d="M0,192L80,186.7C160,181,320,171,480,170.7C640,171,800,181,960,165.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
              ></path>
            </svg>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <h1 className="HomepageTitle">
                  A career with The Salvation Army!
                </h1>
                {/* <h1>A CAREER WITH THE SALVATION ARMY!</h1> */}
                <h3 className="HomepageSubTitle">
                  Looking to use your talents to make a difference? A career
                  with The Salvation Army could be the start of something special.
                  The Salvation Army is a worldwide Christian church and
                  registered charity, which has been fighting against social
                  inequality and transforming lives for over 150 years.
                </h3>
              </div>
              <div className="col-md-4 col-sm-12 video-div">
                <div className="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/otF-2nIs90c?controls=0"
                    title="Salvation Army Careers"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {/* <div >
                <WigLoader
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="1"
                  contentDataChild="0"
                  customDataArray={contentDataData[1].children[0].value}
                  contentDataChildChildChild="1"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                />
              </div> */}
            </div>
          </div>

          <div className="mb-5"></div>

          <div className="row justify-content-center">
            <h2 className="h1-groupcard">Find your career in...</h2>
            <div className="container">
              <div className="row">
                {contentDataData[2].children[1].children.map((column, i) => (
                  <div
                    key={i}
                    className="col-md-3 col-sm-6 col-xs-12 mb-5 vf-parent"
                  >
                    <div className={`info-page-` + getRandomInt(2)}>
                      <div className="card card-role">
                        <a href={column[2].value}>
                          <img
                            className="card-img-top"
                            src={column[0].value}
                            alt={column[1].value}
                          />
                        </a>
                        <a
                          href={column[2].value}
                          className="vf-atag-card-title"
                        >
                          {column[1].value}
                        </a>
                        <p className="card-text mb-5">{column[3].value}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mb-5"></div>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-sm-10 col-xs-12">
                <WigLoader
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="1"
                  contentDataChild="0"
                  customDataArray={contentDataData[3].children[0].value}
                  contentDataChildChildChild="1"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                />
              </div>
            </div>
          </div>

          <div className="mb-3"></div>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 col-sm-12">
                <a
                  href={contentDataData[3].children[1].value}
                  className="btn vf-search-all-btn"
                >
                  {contentDataData[3].children[1].text}
                </a>
              </div>
            </div>
          </div>

          <div className="mb-5"></div>

          <div className="row">
            <div className="col-md-3 col-sm-12 col-sm-offset-0 offset-md-3">
              <div className="row justify-content-center">
                <img
                  src={'/img/Disability-Confident-Leader.jpg'}
                  alt={contentDataData[3].children[2].name}
                />
              </div>
            </div>

            <div className="col-md-5 col-sm-12 ">
              <div className="row justify-content-center disabilityContainer">
                <div className="col-md-8 col-sm-12 col-xs-12">
                  <WigLoader
                    contentDataData={contentDataData}
                    contentMetaData={contentMetaData}
                    contentDataArray="1"
                    contentDataChild="0"
                    customDataArray={contentDataData[3].children[3].value}
                    contentDataChildChildChild="1"
                    AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                    AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                    integratorID={config.integratorID}
                    integratorAccessToken={config.integratorAccessToken}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mb-5"></div>

          <div className="p-3 mb-2 vf-primary-blue text-white">
            <div className="row">
              <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 col-xs-12">
                <h1 className="vf-h1-title">
                  {contentDataData[4].children[0].value}
                </h1>
                <p className="vf-fj-p">
                  {contentDataData[4].children[1].value}
                </p>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12 align-self-center ml-auto">
                <a
                  href={contentDataData[4].children[2].value}
                  className="btn vf-search-now-btn"
                >
                  {contentDataData[4].children[2].text}
                  <img
                    className="vf-search-now-image"
                    src="/img/external-link-blue.svg"
                  />
                </a>
              </div>
            </div>
          </div> */}

          {/* <div className="mb-5"></div> */}

          {/* <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-sm-10 col-xs-12">
                <h1 className="vf-h1-title-benefits center">
                  {contentDataData[5].children[0].value}
                </h1>
                <div className="row justify-content-center">
                  {contentDataData[5].children[1].children.map((column, i) => (
                    <div className="col-md-4 col-sm-10 col-xs-12">
                      <div className="benefits-wrap">
                        <div className="card benefits">
                          <div className="vf-info vf-small-font">
                            Information
                          </div>
                          <h3 className="vf-h4">{column[0].value}</h3>
                          <p className="benefits-p">{column[1].value}</p>
                          <div className="col-12 p-0 benefits-download">
                            <a
                              href={column[3].value}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="row justify-content-center">
                                <div className="col-9 p-0 align-self-center">
                                  <div className="vf-small-font">
                                    Booklet / {column[2].value} KB
                                  </div>
                                </div>
                                <div className="col-3 p-0 ml-auto">
                                  <div className="e-round-red-icon">
                                    <svg
                                      aria-hidden="true"
                                      data-prefix="fas"
                                      data-icon="arrow-down"
                                      className="svg-inline--fa fa-arrow-down fa-w-14"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div> */}

          <div className="mb-5"></div>

          <div className="p-3 mb-2 vf-primary-grey text-white">
            <div class="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h1 className="vf-h1-title-role">
                    {contentDataData[6].children[0].value}
                  </h1>
                  <p style={{ color: 'black' }} className="paragraph-text">
                    {/* If you are looking for roles at our affiliates: Reliance Bank, Salvation Army Housing Association (saha), Salvation Army General Insurance Company (SAGIC) or International Headquarters (IHQ). */}
                    If you are looking for roles at our affiliates: Reliance
                    Bank, Salvation Army Housing Association (saha), Salvation
                    Army General Insurance Company (SAGIC) or International
                    Headquarters (IHQ). Please visit their individual websites
                    for their current vacancies.
                  </p>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12 align-self-center ml-auto">
                  {/* <a
                  href={contentDataData[6].children[2].value}
                  className="btn vf-affiliate-btn"
                >
                  {contentDataData[6].children[2].text}
                  <img
                    className="vf-search-now-image"
                    src="/img/external-link-white.svg"
                  />
                </a> */}
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5"></div>

          {/* <div className="container">
            <div className="row">
              <div className="col-md-5 offset-md-1">
                <h2 >Other roles</h2>
                <p className='paragraph-text'>Please follow these links if you are looking for roles at our affiliates: Reliance Bank, Salvation Army Housing Association (saha), Salvation Army General Insurance Company (SAGIC) or International Headquarters (IHQ).</p>
              </div>
              <div className="col-md-5">
                <WigLoader
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="1"
                  contentDataChild="0"
                  customDataArray={contentDataData[7].children[0].value}
                  contentDataChildChildChild="1"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                />
              </div>
            </div>
          </div> */}

          <div className="mb-5"></div>

          <div className="row justify-content-center offset-lg-2">
            <div className="row">
              {contentDataData[8].children[0].children.map((column, i) => (
                <div
                  key={i}
                  className="col-md-2-4 col-sm-6 col-xs-12 mb-5 vf-parent"
                >
                  <div className={`info-page-` + getRandomInt(2)}>
                    <div className="card card-role">
                      <a href={column[2].value}>
                        <img
                          className="card-img-top"
                          src={column[0].value}
                          alt={column[1].value}
                        />
                      </a>
                      <a href={column[2].value} className="vf-atag-card-title">
                        {column[1].value}
                      </a>
                      <p className="card-text mb-5">
                        <WigLoader
                          contentDataData={contentDataData}
                          contentMetaData={contentMetaData}
                          contentDataArray="1"
                          contentDataChild="0"
                          customDataArray={column[3].value}
                          contentDataChildChildChild="1"
                          AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                          AccroPressLocalEndpoint={
                            config.AccroPressLocalEndpoint
                          }
                          integratorID={config.integratorID}
                          integratorAccessToken={config.integratorAccessToken}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
